<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: "CardContent",
    data() {
        return {
            baseClasses: "px-6 py-6 space-y-6",
        };
    },
    computed: {
        classes() {
            return `card-content ${this.baseClasses}`;
        },
    },
};
</script>
